<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="券包编号">
          <a-input
            v-model:value="params.couponPackageId"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="名称">
          <a-input
            v-model:value="params.couponPackageName"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="券类型">
          <a-select
            v-model:value="params.couponType"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            :options="couponTypes"
          ></a-select>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="状态">
          <a-select
            v-model:value="params.couponState"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            :options="couponStates"
          >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button
            class="btn-success"
            @click="showAddModal"
            perm="coupon:vip:add"
          >
            <template #icon>
              <PlusCircleOutlined />
            </template>
            添加
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="couponPackageId"
          table-layout="auto"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ x: 1500, y: 604 }"
          bordered
        >
          <template #operation="{ record }">
            <div>
              <TipButton
                v-if="record.state !== couponStatesEnum.ENDED"
                perm="coupon:vip:edit"
                btn-class="btn-success"
                title="编辑"
                @btnClick="showEditModal(record.id, record, false)"
              >
                <template #default>
                  <EditOutlined />
                </template>
              </TipButton>
              <TipButton
                v-if="
                  record.state === couponStatesEnum.COLLECTED ||
                  record.state === couponStatesEnum.ENDED
                "
                perm="coupon:vip:view"
                btn-class="btn-primary"
                title="查看"
                @btnClick="showEditModal(record.id, record, true)"
              >
                <template #default>
                  <EyeOutlined />
                </template>
              </TipButton>
              <TipButton
                v-if="record.state === couponStatesEnum.RECEIVING"
                btn-class="btn-warning"
                perm="coupon:vip:end"
                title="结束"
                @btnClick="endItem(record.couponPackageId)"
              >
                <template #default>
                  <PoweroffOutlined />
                </template>
              </TipButton>
              <TipButton
                perm="coupon:vip:add"
                btn-class="btn-danger"
                title="删除"
                @btnClick="delItem(record.couponPackageId)"
              >
                <template #default>
                  <DeleteOutlined />
                </template>
              </TipButton>
            </div>
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <add
    v-if="addShow"
    :modal-ref="$refs.modalContainer"
    @close="hideAddModal"
  ></add>
  <edit
    v-if="editShow"
    :id="currentId"
    :record="record"
    :isView="isView"
    :modal-ref="$refs.modalContainer"
    @close="hideEditModal"
  ></edit>
</template>

<script setup>
import useCouponVIP from "@/hooks/coupon/useCouponVIP";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted, ref } from "vue";
import useMessage from "@/hooks/useMessage";
import { deleteCoupon, endCoupon } from "@/api/coupon/couponVipApi";
import Add from "@/views/Coupon/VIPCoupon/components/Add.vue";
import Edit from "@/views/Coupon/VIPCoupon/components/Edit.vue";
import TipButton from "@/components/TipButton.vue";
import CardHeader from "@/components/CardHeader/index.vue";
import { useStore } from "vuex";

const modalContainer = ref(null);

const {
  params,
  dataList,
  total,
  loading,
  columns,
  loadData,
  couponTypes,
  couponStates,
  couponStatesEnum,
} = useCouponVIP();

const { page, pageSize, currentChange, doQuery } = usePagination(loadData);

const {
  currentId,
  addShow,
  editShow,
  showAddModal,
  showEditModal,
  hideAddModal,
  hideEditModal,
  record,
  isView,
} = useAddExit(doQuery);

const { showConfirm, showLoading, closeLoading, showTipMessage, showErr } =
  useMessage();

const store = useStore();

onMounted(() => {
  loadData(page.value, pageSize.value);
});

const delItem = async couponPackageId => {
  let action = await showConfirm("删除后不可恢复，是否确认删除当前优惠券?");
  if (action) {
    await showLoading("正在删除，请稍后...");
    try {
      await deleteCoupon({
        couponPackageId,
        delAccount: store.state.user.name,
      });
      await closeLoading();
      await showTipMessage("删除成功", "success");
      doQuery();
    } catch (e) {
      await closeLoading();
      await showErr(e);
    }
  }
};
const endItem = async couponPackageId => {
  let action = await showConfirm(
    "结束后，该优惠券不能再被领取，但已领取的优惠券仍能使用。是否确认结束优惠券？",
  );
  if (action) {
    await showLoading("正在处理，请稍后...");
    try {
      await endCoupon({
        couponPackageId,
        updAccount: store.state.user.name,
      });
      await closeLoading();
      await showTipMessage("结束优惠券成功", "success");
      doQuery();
    } catch (e) {
      await closeLoading();
      await showErr(e);
    }
  }
};
</script>

<style scoped></style>
